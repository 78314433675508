import './navbar.css'
import {useNavigate } from 'react-router-dom'
import { HOME_ROUTE,TRAINING_ROUTE,PROJECT_ROUTE,ABOUT_ROUTE } from '../../utils/const'


const Navbar =() =>
{
    const navigate = useNavigate ()

    return(<div className='navbar'>
        <div className='navContent'>
        <button onClick={() => navigate(HOME_ROUTE)}>Главная</button>
        <button onClick={() => navigate(TRAINING_ROUTE)}>Теория</button>
        <button onClick={() => navigate(PROJECT_ROUTE)}>Проекты</button>
        <button onClick={() => navigate(ABOUT_ROUTE)}>Обо мне</button>
        </div>

    </div>)
}
export default Navbar;