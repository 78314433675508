import React, { useEffect, useState } from "react";
import Card from '../components/card/Card'
import { getAllProjects } from '../http/Api'
import {PROJECT_ROUTE} from '../utils/const'

const Projects = () => {
    const [projects,setProjects]=useState([])    
    const getTrainings  =  () =>
    {
        getAllProjects().then((data) => {        
            setProjects(data)}
        )
    }

    useEffect(getTrainings,[])
    return (<div className="container">
        {projects.map((data) => {return (<Card key={data._id} info={data} route={PROJECT_ROUTE}/>) })}
    </div>)
}
export default Projects