import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getOneProjects } from '../http/Api'
import { useNavigate } from 'react-router-dom'
import { PROJECT_ROUTE } from '../utils/const'

const ProjectOne = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [info, setInfo] = useState({ skill: [] })

    useEffect(() => {
        getOneProjects(id)
            .then((data) => {
                //console.log(data.error)
                if (data===500) {
                    navigate(PROJECT_ROUTE)
                    /*alert("cтраницы не существует")                    */
                }
                else {
                    setInfo(data)

                }
            })
            .catch()
    }, [id, navigate])

    return (
        <>
            <div className='containerBox'>
                <img className='imgPages' src={info.urlBigImg} alt={info.title}></img>

                <div className='info'>
                    <h2>{info.title}</h2>
                    <ul>                        
                        <li>Технологии: {info.skill.map((value, i, arr) => { return (i !== arr.length - 1) ? (value + ', ') : (value + '.') })}</li>
                        <li>Ссылка: {info.url ? <a href={info.url} target="_blank" rel="noreferrer">{info.url}</a> : "отсуствует"}</li>
                        {info.url? <li>GitHub: <a href={info.urlGitHub} target="_blank" rel="noreferrer">{info.urlGitHub}</a></li>:"" }                        
                        <li>Количество просмотров страницы - {info.viewsCount}</li>
                    </ul>
                </div>
                {info.about?info.about.map((data,i)=>{return(<p key={i}>{data}</p>)}):""}
            </div>
        </>
    )

}
export default ProjectOne