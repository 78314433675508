import axios from 'axios';

const ApiSrc=process.env.REACT_APP_API_URL+'api/site/'

export const getAllTrainings = async ()=>
{
    const AllTrainings = await axios.get(ApiSrc+'trainings/')
    
    return AllTrainings.data;
}

export const getOneTrainings = async (id)=>
{
    try{
    const OneTraining = await axios.get(ApiSrc+'trainings/'+id)
   
    return OneTraining.data;
    }
    catch(err)
    {
        return 500;
    }
}
export const getAllProjects = async ()=>
{
    const AllTrainings = await axios.get(ApiSrc+'projects/')
    
    return AllTrainings.data;
}

export const getOneProjects = async (id)=>
{
    try{
    const OneTraining = await axios.get(ApiSrc+'projects/'+id)
   
    return OneTraining.data;
    }
    catch(err)
    {
        return 500;
    }
}
export const getProfile = async ()=>
{
    const AllTrainings = await axios.get(ApiSrc+'profile/')
    
    return AllTrainings.data;
}

