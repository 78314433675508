import { getAllTrainings } from '../../http/Api'
import Slider from "react-slick";
import React, { useEffect, useState } from "react"
import {useNavigate } from 'react-router-dom'
import './slider.css'
import { SLIDER_SETTING } from '../../utils/const';

const SliderTrainings = () => {
    const navigate = useNavigate ()
    const settings = {...SLIDER_SETTING};
    const [trainings, setTrainings] = useState([])
    const getTrainings = () => {
        getAllTrainings().then((data) => {
            setTrainings(data)
        }
        )
    }

    useEffect(getTrainings, [])
    if (trainings.length === 0) {
        return (<div></div>)
    }

    return (
        <div>
            <h2>Изученые материалы</h2>
            <Slider {...settings}>                
                    {trainings.map((info) => {
                        return (
                            <div key={info._id}>
                            <div className="containerBox">
                                <img className='imgPages' src={info.urlBigImg} alt={info.title}></img>
                                <div className='info'>                                    
                                <h2>{info.title}</h2>
                                <ul>
                                    <li>{info.isBook ? 'Книга' : 'Интернет источник'}</li>
                                    <li>Что изучалось: {info.skill.map((value, i, arr) => { return (i !== arr.length - 1) ? (value + ', ') : (value + '.') })}</li>
                                    <li>Ссылка: {info.url ? <a href={info.url} target="_blank" rel="noreferrer">{info.url}</a> : "отсуствует"}</li>
                                    <li>Прогресс: {info.completed ? 'Изучено' : 'в процессе'}</li>
                                </ul>
                                <p className='linc' onClick={()=>{navigate('/training/'+info._id)}}>ПОДРОБНЕЕ</p>
                                </div>
                            </div>
                            </div>
                        )
                    })}
            </Slider>
        </div>
    )

}
export default SliderTrainings