import Card from '../components/card/Card'
import React, { useEffect, useState } from "react";
import { getAllTrainings } from '../http/Api'
import {TRAINING_ROUTE} from '../utils/const'




const Trainings = () => {
    const [trainings,setTrainings]=useState([])    
    const getTrainings  =  () =>
    {
        getAllTrainings().then((data) => {        
            setTrainings(data)}
        )
    }

    useEffect(getTrainings,[])
    return (<div className="container">
        {trainings.map((data) => {return (<Card key={data._id} info={data} route={TRAINING_ROUTE}/>) })}
    </div>)
}
export default Trainings