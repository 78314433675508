import { useEffect, useState } from 'react'
import { getProfile } from '../http/Api'
const About = () => {
    const [info, setInfo] = useState([])
    const Profile = () => {
        getProfile().then((data) => {
            setInfo(data)
        }
        )
    }

    useEffect(Profile, [])
    if (info.length === 0) {
        return (<div></div>)
    }
    return(<>
    <div className='containerBox'>
                <img className='imgMe' src={info.urlImg} alt="Me"></img>
                <div className='info'>
                    <h2>Кратко обо мне</h2>
                    <ul> 
                        <li>ФИО: {info.fio}</li>
                        <li>Дата Рождения: {info.birthDay.day}.{(info.birthDay.month+1).toString().length?"0"+(info.birthDay.month+1).toString():(info.birthDay.month+1)}
                        .{info.birthDay.year}г.</li>
                    </ul>
                </div>
                {info.about?info.about.map((data,i)=>{return(<p key={i}>{data}</p>)}):""}
            </div>
    </>)
}
export default About