import SliderTrainings from "../components/sliders/SliderTrainings";
import SliderProjects from "../components/sliders/SliderProjects";

const Home = () => {
    return (<div>
      <div className="containerBox">
                                <img className='imgMe' src="https://api.pycel.ru/static/me.jpg" alt="Me"></img>
                                <div className='info'>
                                    <h2>Здравствуйте, я Садыков Руслан</h2>
                                    <p>Я очень рад, что вы решили заглянуть и узнать меня лучше. Тут вы можете познакомиться с моими проектами,
                                         а также технологиями, которые я изучал для их реализации. Как и у Вас есть свой сайт, где я постарался
                                          узнать о Вас больше, так и Вы можете познакомиться со мной. И мы вместе подготовимся к нашему совместному интервью.</p>
                                </div>
                            </div>
        <SliderTrainings/>
        <SliderProjects/>
    </div>)
}
export default Home