import About from "../pages/About"
import Home from "../pages/Home"
import Projects from "../pages/Projects"
import Trainings from "../pages/Trainings"
import TrainingOne from "../pages/TrainingOne"
import ProjectOne from "../pages/ProjectOne"
import {  HOME_ROUTE, ABOUT_ROUTE, TRAINING_ROUTE , PROJECT_ROUTE } from "../utils/const"

export const publicRoutes = [
    {
        path : HOME_ROUTE,
        element:<Home/>
    },
    {
        path : ABOUT_ROUTE,
        element:<About/>
    },
    {
        path : TRAINING_ROUTE,
        element:<Trainings/>
    },
    {
        path : PROJECT_ROUTE,
        element:<Projects/>
    },
    {
        path : TRAINING_ROUTE+'/:id',
        element:<TrainingOne/>
    },
    {
        path : PROJECT_ROUTE+'/:id',
        element:<ProjectOne/>
    },
]