import { getAllProjects } from '../../http/Api'
import Slider from "react-slick";
import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import './slider.css'
import { SLIDER_SETTING } from '../../utils/const';


const SliderProjects = () => {
    const navigate = useNavigate()
    const settings = {...SLIDER_SETTING};
    const [projects, setProjects] = useState([])
    const getProjects = () => {
        getAllProjects().then((data) => {
            setProjects(data)
        }
        )
    }

    useEffect(getProjects, [])
    if (projects.length === 0) {
        return (<div></div>)
    }

    return (
        <div>
            <h2>Проекты</h2>
            <Slider {...settings}>
                {projects.map((info) => {
                    return (
                        <div key={info._id}>
                            <div className="containerBox">
                                <img className='imgPages' src={info.urlBigImg} alt={info.title}></img>
                                <div className='info'>
                                    <h2>{info.title}</h2>
                                    <ul>
                                        <li>Технологии: {info.skill.map((value, i, arr) => { return (i !== arr.length - 1) ? (value + ', ') : (value + '.') })}</li>
                                        <li>Ссылка: {info.url ? <a href={info.url} target="_blank" rel="noreferrer">{info.url}</a> : "отсуствует"}</li>
                                        {info.urlGitHub ? <li>GitHub: <a href={info.urlGitHub} target="_blank" rel="noreferrer">{info.urlGitHub}</a></li> : ""}
                                    </ul>
                                    <p className='linc' onClick={() => { navigate('/project/' + info._id) }}>ПОДРОБНЕЕ</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )

}
export default SliderProjects