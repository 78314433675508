export const HOME_ROUTE = '/'
export const ABOUT_ROUTE = '/about'
export const TRAINING_ROUTE = '/training'
export const PROJECT_ROUTE = '/project'
export     const SLIDER_SETTING = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true
};
