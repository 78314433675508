import { Routes, Route, Navigate } from 'react-router-dom'
import { publicRoutes } from "./route"

const AppRouter = () => {
    //    console.log(publicRoutes)

    return (
        <div className='content'>
            <Routes>
                {publicRoutes.map(({ path, element }) =>
                    <Route key={path} path={path} element={element} />
                )}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>

    );
};

export default AppRouter;