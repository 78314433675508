import './card.css'
import {useNavigate } from 'react-router-dom'


const Card = (props) => {
    const navigate = useNavigate ()
    return (
        <div className="card">
            <div className="img-box">
                <img src={props.info.urlMinImg} alt={props.info.title}></img>
            </div>
            <div className="content">
                <h2>{props.info.title}</h2>
                <div className='box'>
                        {props.info.about[0]}
                </div>
                <p onClick={()=>{navigate(props.route+'/'+props.info._id)}}>Подробнее</p>
            </div>
        </div>
    )
}
export default Card;