import './App.css';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import { BrowserRouter } from "react-router-dom";
import AppRouter from './components/AppRouter';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";

function App() {
  return (
  <BrowserRouter>
    <div className="App">
      <Navbar />
      <AppRouter />
      <Footer />
    </div>
  </BrowserRouter>

  );
}

export default App;
